import { useContext, useEffect } from 'react'
import Carousel from './components/Carousel'
import { useGetStories } from './hooks/useGetStories'
import CarouselSkeleton from './components/CarouselSkeleton'
import { AmpContext } from './context/ampContext'
import { cn } from './utils/cn'
import { useAtomValue } from 'jotai'
import { urlParams } from './states'

function App() {
  const { data, isPending, isError } = useGetStories()
  const { isFullScreen } = useContext(AmpContext)
  const { editorial } = useAtomValue(urlParams)

  useEffect(() => {
    const root = document.documentElement

    if (editorial) {
      root.setAttribute('data-theme', editorial)
    }

    return () => {
      root.setAttribute('data-theme', 'cnc')
    }
  }, [editorial])

  return (
    <div className={cn(isFullScreen && 'fixed inset-0 h-screen bg-black')}>
      {isPending || isError ? <CarouselSkeleton /> : data && data.length > 0 ? <Carousel collection={data} /> : null}
    </div>
  )
}

export default App
